import React, {useState, useRef, useEffect} from 'react';
import SideBar from './SideBar.tsx'
import upload_file_logo from '../img/upload_file_logo.svg';  
import close_button_logo from '../img/close_button_logo.svg';
import button_upload_file_logo from '../img/button_upload_file_logo.svg';
import advanced_settings_logo from '../img/advanced_settings_logo.svg';
import IconButton from './IconButton.tsx';
import { axiosPrivate } from '../hooks/axios.js';
import Loading from './Loading.tsx'
import { useLocation, useNavigate } from 'react-router-dom';
import {success_notification , error_notification} from '../hooks/utils.tsx';
import save_changes_button_logo from '../img/save_changes_button_logo.svg';
import add_offer_field_logo from '../img/add_offer_field_logo.svg';
import ChangeTemplate from './ChangeTemplate.tsx'
import TemplatesPage from './TemplatesPage.tsx'
import { motion } from 'framer-motion';
import  ConfirmationModal  from './ConfirmationModal.tsx';
import remove_logo from '../img/option_settings_remove_logo.svg';
import useConfirmModal from '../hooks/useConfirmModal.tsx';
import { useContextSelector } from 'use-context-selector';
import { AppContext } from '../AppContext.js';
function NameField(props) {
    return (<div className={`flex flex-col ${props.className}`}>
        <span className="text-2xl font-semibold ml-[2vw]">Name</span>
        <input type="text" onChange={props.onChange} value={props.value} className=" bg-gray-100 rounded-lg mt-2 p-4" placeholder="Enter the bot name here..."/>
    </div>);
}

function UploadFileIcon(props) {
  return <img src={upload_file_logo} alt={props.name} className={`${props.className}`} />;    
}

function UploadedFile(props) {
  return (
    <div className = {`${props.className} ${props.broken ? "border-2 border-red-500" : ""} flex flex-col mb-2 w-[20vw] rounded-[1vw] p-8 bg-gray-100 `}>
      <div key={props.index} className="flex w-full">
        <div className="h-[2.5vw] w-[2.5vw]">
          <UploadFileIcon name={props.name} className = "w-full h-full object-cover" />
        </div>
        <span className = "text-lg font-medium ml-4 max-w-[13vw]">{props.name}</span>
      </div>
      {/* <div className="w-[100%] mt-5 bg-gray-200 h-2 rounded-full">
          <div
            className="bg-blue-500 h-2 rounded-full"
            style={{ width: `${props.progress}%` }}
          ></div>
        </div> */}

        {props.broken ? <p className="text-red-500 mt-2">The file couldn't be indexed</p> : <p></p>}
    </div>);
}



function SingleFileUploadButton({className, files, setFiles, notIndexed}) {
  
 
  const [errorMessage, setErrorMessage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [dragging, setDragging] = useState(false);
  const file_input_ref = useRef(null);

  const LIMIT = 100;



  if (files.length == LIMIT) {
    if (errorMessage != '') setErrorMessage('');
    if (!isButtonDisabled) setIsButtonDisabled(true);
  } else if (files.length > LIMIT && errorMessage != '') {
    setErrorMessage(`You can upload up to ${LIMIT} files only.`);
    setIsButtonDisabled(true);
  } else if (files.length < LIMIT && isButtonDisabled) {
    setErrorMessage('');
    setIsButtonDisabled(false);
  }

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const filesWithProgress = selectedFiles.map((file) => ({
      file,
      name: file.name,
      progress: 0
    }));

    setFiles((prevFiles) => [...prevFiles, ...filesWithProgress]);

    
  };

 

  const handleDelete = (fileData, index) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };


  const handleDragEnter = (e) => {
    if (!isButtonDisabled) {
      e.preventDefault();
      e.stopPropagation();
      setDragging(true);
    }
  };

  const handleDragOver = (e) => {
    if (!isButtonDisabled) {
      e.preventDefault();
      e.stopPropagation();
      setDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e) => {
    if (!isButtonDisabled) {
      e.preventDefault();
      e.stopPropagation();
      setDragging(false);
      e.target = e.dataTransfer;
      handleFileChange(e);   
    } 
  };

  const handleInputClick = () => {
    if (file_input_ref.current) {
      file_input_ref.current.click();
    }
  };


  if (files.length == 0) {
    return (
        <div className={`flex cursor-pointer flex-col justify-center items-center h-30 border-2 w-[30vw] border-gray-100 rounded-[1vw] p-4 ${className} ${dragging ? 'bg-gray-100' : ''}`}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              onClick = {() => handleInputClick()}>
          <UploadFileIcon name="Upload File" className = "2vw"/>
          <input
            type="file"
            className="hidden"
            id="fileInput"
            onChange={handleFileChange}
            accept=".jpg,.jpeg,.png,.svg"
            disabled={isButtonDisabled}
            ref={file_input_ref}
          />
          <label
            htmlFor="fileInput"
            className={`cursor-pointer px-4 py-2  text-lg text-center text-gray-400 ${
              isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}>
            <span className ="text-black font-semibold">Click to upload </span> or drag and drop<br />
            SVG, PNG, JPG (max. 100mb)
          </label>  
        </div>
    );
  } else {
    return (
      <div className = "flex flex-col">
        {errorMessage && <div className="text-red-500 mt-2">{errorMessage}</div>}
          <div className="w-full mt-4 grid grid-cols-3 gap-4">
            {files.map((fileData, index) => (
                <div className="relative" key={index}>
                <UploadedFile broken={notIndexed?.includes(fileData.name)} key={index} {...fileData}/>
                <button className={`absolute top-[-1vh] right-[1.5vw] z-50 bg-white rounded-full`} onClick ={()=>{handleDelete(fileData, index);}}>
                  <img src={close_button_logo} alt="file" className="bg-transparent	w-[2vw]"/>
                </button>
              </div>)
            )}
          </div>
        <input
          type="file"
          className="hidden"
          id="fileInput"
          onChange={handleFileChange}
          accept=".jpg,.jpeg,.png,.svg,.docx,.txt,.pdf"
          disabled={isButtonDisabled}
          ref={file_input_ref}
        />
        <IconButton onClick = {handleInputClick} className = "mt-4 ml-6" icon_url = {button_upload_file_logo} name = "Upload file" background_color = "bg-black" text_className = "text-white"/>
      </div>
    )
  }
}

function InstructionsField(props) {
    return (<div className={`flex flex-col ${props.className}`}>
        <span className="text-2xl font-semibold ml-[2vw]">Instructions</span>
        <textarea className=" resize-none bg-gray-100 rounded-lg mt-2 p-2" 
        onChange={props.onChange}
        value={props.value}
        rows={5}
        placeholder={"Enter instructions for the bot, e.g: \nYou're a bot that helps people with their homework. You can answer questions about math, science, history, and more. You can also help with writing essays and studying for tests. You can even help with coding problems!"}/>
    </div>);
}

function KnowledgeBaseField(props) {
    
    return (<div className={`flex flex-col ${props.className}`}>
        <span className="text-2xl font-semibold ml-[2vw]">Knowledge</span>
        <SingleFileUploadButton className="mt-4" files={props.files} setFiles={props.setFiles} notIndexed={props.notIndexed} />
    </div>);
}   

function ScrapWebsiteField(props) {
  return (<div className={`flex flex-col ${props.className}`}>
      <span className="text-2xl font-semibold ml-[2vw]">Scrap your website (optional)</span>
      <input type="text" value={props.value} onChange={props.onChange} className=" bg-gray-100 rounded-lg mt-2 p-4" placeholder="www.your_website.com"/>
      <IconButton className = "mt-4 ml-6 opacity-70" name = "Advanced settings" icon_url = {advanced_settings_logo} background_color = "bg-black" is_disabled = {true} text_className = "text-white"/>
    </div>);
}


function MainVersion() {
  return (<div>
    <NameField className = "mt-4"/>
    <InstructionsField className = "mt-4" /> 
    <KnowledgeBaseField className = "mt-4" name = {"Knowledge"} /> 
    <ScrapWebsiteField className = "mt-4" name = {"Scrap your website (optional)"}/>
    <IconButton className = "mt-4 ml-6 opacity-70" name = "Advanced settings" icon_url = {advanced_settings_logo} background_color = "bg-black" is_disabled = {true} text_className = "text-white"/>
    <div className = "flex flex-col items-center">
        <IconButton name = "Save changes" background_color = "bg-emerald-500" className = "hover:bg-green-400 transition-color duration-200" text_className = "text-white" icon_url = {save_changes_button_logo} />
    </div>
  </div>);
}


const Dropdown = ({ options, name, selected_option, handleOptionClick, handleOpen, current_open, dropdown_id}) => {
  const isOpen = (current_open === dropdown_id);

  return (
    <div className="relative inline-block text-xl  text-left">
      <div className="w-60">
        <button
          className="flex flex-row justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
          aria-haspopup="true"
          aria-expanded="true"
          aria-controls="options-menu"
          id = {name}
          onClick={handleOpen}
        >
          {selected_option}
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            id = {name}
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06 0L10 10.93l3.71-3.72a.75.75 0 011.06 1.06l-4.25 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>  
      {isOpen && (
        <div
          className="origin-top-right absolute z-10 right-[-3] mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="py-1">
            {options.map((option) => (
              <button
                key={option}
                className="px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};


const getPropName = (str_name)=>{
  const ind = str_name.indexOf('_');
  if(ind == -1){
    // basically something went wrong
    return str_name;
  }
  return str_name.slice(ind+1);
}
function JsonVisualEditor({obj, setObj}){

  const getPropName = (str_name)=>{
    const ind = str_name.indexOf('_');
    if(ind == -1){
      // basically something went wrong
      return str_name;
    }
    return str_name.slice(ind+1);
  }

  const handleAddProps = () => {  
    const upd_lambda = (cur_obj) =>{
      let nw_entries = Object.entries(cur_obj);
      nw_entries.push([`${nw_entries.length}_`, ""])
      return Object.fromEntries(nw_entries);
    }
    setObj(upd_lambda)
  };

  const handleDeleteProps = (index) => {
    const upd_lambda = (cur_obj) =>{
      let nw_entries = Object.entries(cur_obj);
      nw_entries = nw_entries.filter(
        ([key, value], ind) => (ind != index) 
      )
      return Object.fromEntries(nw_entries);
    }
    return setObj(upd_lambda)
  }
  const handleClear = () => {
    setObj((cur_obj) => {});
  };

  const fieldStyleLegacy = 'appearance-none bg-gray-100 rounded-2xl py-3 w-80 px-4 text-xl';
  const fieldStyle = 'appearance-none bg-gray-100 rounded-1xl py-3 w-60 px-2 text-l ml-2'

  // initial={{ opacity: 0, height: 0 }}
  // animate={{ opacity: 1, height: 'auto' }}
  // exit={{ opacity: 0, height: 0 }}
  // transition={{ duration: 0.5 }}
  

  return <motion.div
  initial={{ opacity: 0, maxHeight: 0 }}
  animate={{ opacity: 1, maxHeight: 1000 }} // Arbitrary large value to allow full height expansion
  exit={{ opacity: 0, maxHeight: 0 }}
  transition={{ duration: 0.7, ease: "easeInOut" }}
  className="overflow-hidden"
><ul className="w-full">
  
  {Object.entries(obj).map((offer, index) => {

      const handleNameChange = (event) => { 
        const upd_lambda = (cur_obj) =>{
          let nw_entries = Object.entries(cur_obj);
          let cur_value = nw_entries[index][1]
          nw_entries[index] = [String(index)+"_"+event.target.value, cur_value]
          return Object.fromEntries(nw_entries);
        }
        setObj(upd_lambda);
      };

      const handleContentChange = (event) => { 
        const upd_lambda = (cur_obj) =>{
          let nw_entries = Object.entries(cur_obj);
          let cur_name = nw_entries[index][0]
          nw_entries[index] = [cur_name, event.target.value]
          return Object.fromEntries(nw_entries);
        }
        setObj(upd_lambda);
      };
      
      if(offer[0][0] == '#'){
        return <></>
      }
      
      if(getPropName(offer[0]) == "image"){
        return <li key = {index} className = "flex flex-rows items-center mt-4">
        <div className={`${fieldStyle} font-bold`}>
            Image
        </div>
        <input type="text" value={offer[1]} className={fieldStyle} placeholder="Put the image url there" onChange={handleContentChange}/>
        <div className="w-8 ml-4"></div>
      </li>
      }

      return <li key = {index} className = "flex flex-rows items-center mt-4">
        <input value={getPropName(offer[0])} type="text" onChange = {handleNameChange} className={fieldStyle} placeholder="Put the name here"/>
        <input value={offer[1]} type="text" onChange = {handleContentChange} className={fieldStyle} placeholder="Put the description here"/>
        <button className="ml-4" onClick = {() => handleDeleteProps(index)}>
          <img src = {close_button_logo} alt = "delete offer" className = "w-8 h-8"/>
        </button>
      </li>;
    })}
  </ul>
  <div className= "flex flex-row mt-4 items-center ml-2 w-full justify-center">
        
        <button className="flex items-center border-2 font-bold  border-black w-20 py-1 px-2 rounded-xl text-sm justify-center" onClick={handleAddProps}>
          <p> Add </p>
          {/* <img src = {add_offer_field_logo} alt = "add offer" className = "ml-2 w-6 h-6 cursor-pointer"/> */}
        </button>
        <div className="w-8 ml-4"></div>
        {/* <button className="text-l font-bold border-dashed rounded-xl border-2 px-4 py-2 border-black ml-[5vw]" onClick = {handleClear}>
          Delete all
        </button> */}
  </div>

  </motion.div>
}

function JsonCardPreview({props, image}){
  return <div className = "flex flex-col w-[70%]">
  <div className = "text-5xl font-bold text-center">Preview</div>
  <div className = "flex flex-col items-center text-2xl mt-[2vh]">
    <img loading = "lazy" alt="offer" src = {image} className = "w-[90%] rounded-t-xl"/>
    <div className = "w-[90%] bg-gray-100 rounded-b-xl px-4 py-5">
      {props.map((item, index) => (
        <div key={index}>
          <p className="font-bold break-all">{item.name}</p>
          <p className = "break-all">{item.content}</p>
        </div>
      ))}
    </div>
  </div>
</div>
}

function OffersVersion(props) {
  const navigate = useNavigate();
  const [files, setFiles] = useState([])
  const [notIndexed, setNotIndexed] = useState([])
  // const available_types = ["Image", "Text", "Button", "Button with url"];
  // const defaultTextsForType = {
  //   "Image": ["Put image url here"],
  //   "Text": ["Put text of your offer here"],
  //   "Button": ["Put text of button"],
  //   "Button with url": ["Put text of button", "Put the url, opened on click"],
  // };
  const [current_open, setCurrentOpen] = useState(-1);
  const [error, setError] = useState("")

  const [offers, setOffers] = useState([{"0_image": ""}])
  const [selectedOffer , setSelectedOffer] = useState(-1);

  const addNewOffer = ()=>{
    setOffers(cur_offers=>{
      let nw_offers = [...cur_offers];
      nw_offers.push({"0_image": ""});
      return nw_offers;
    })
  }
  const add_offers = async(offers)=>{
    try{

      


      await axiosPrivate.post('/update-offers', {
          offers,
          config_id : props.config.config_id
      });
      console.log("successfully updated the offers")
    }catch(error){
      console.log("error while adding offers " , error);
      setError("Error while adding offers" + error?.message);
    }
  }
  
  const updateOffer = (index, upd_lambda)=>{
    setOffers(cur_offers=>{
      let nw_offers = [...cur_offers];
      console.log("the object was " , nw_offers[index])
      nw_offers[index] = upd_lambda(nw_offers[index]);
      console.log("the object became ", nw_offers[index])

  
      return nw_offers;
    })
  }
  const update_config = async()=>{
    try{
      await axiosPrivate.post('/update-config', {
        config_id : props.config.config_id,
        name : name,
        instruction : "",
        schema: {
          type: "offers"
        }
      })
    }catch(e){
      console.log("error while updating config")
      setError("Error while updating config" + e?.message + ". Try again later.")
    }

  }
  const full_offer_entries = useRef([]);

  const transformOffers = (offers)=>{
    console.log("IN THE TRANSFORM " , offers)
    let updated_offers = offers.map((offer, index) => {
      let updated_info = offer;
      let full_offer = full_offer_entries.current.find((cur_offer)=>(cur_offer.offer_id == updated_info['#_']));
      if(!full_offer){
        full_offer = {
          offer_id: -1,
          config_id: props.config.config_id,
        }
      }else{
        full_offer.offer_id = -1;
        // !!! IMPORTANT, this is because currently we delete all the offers of the config on the backend and add all of them once again, need change in the future
      }
      console.log("the entries are " , Object.entries(updated_info).filter(([key, value])=>(key != '#_')));
       let updated_card_info = Object.fromEntries(
          Object.entries(updated_info).filter(([key, value])=>(key != '#_')).map(([key, value])=>([getPropName(key), value]))
        );
        full_offer.card_description = updated_card_info;
     return full_offer;
    });
    console.log("updated offers are " , updated_offers)
    return updated_offers;
  }

  const get_offers = (loading = true)=>{
    if(loading == true){
      props.setLoading(true);
    }
    axiosPrivate.get(`/get-offers/${props.config.config_id}`).then(response => {
      if(loading == true){
        props.setLoading(false);
      }
      const offers = response.data.offers;
      full_offer_entries.current = offers;
      const card_part = full_offer_entries.current.map((offer, index) => {
        let obj = offer.card_description;
        obj['#_'] = offer.offer_id;
        let id = 0;

        console.log("the offer is " , offer);
        if(offer.images && offer.images.length > 0){
          obj[`${id}_images`] = offer.images;
          id += 1;
        }
        if(offer.full_description){
          obj[`${id}_full_description`] = offer.full_description;
        }
        return obj;
      });
      setOffers(card_part);
      console.log("offers are " , offers);
    }).catch(error => {
      if(loading == true){
        // props.setLoading(false);
      }
      setError('Error while loading offers try again later.')
    });
  }
  const first = useRef(false);
  useEffect(()=>{
    if(first.current == true){
      return;
    }
    first.current = true;
    get_offers(true);
  }, [])

  const saveChanges = async ()=>{
    try{
      props.setLoading(true);
      transformOffers(offers);
      await update_config();
      await add_offers(transformOffers(offers));
      success_notification("Successfully updated config");
      props.setLoading(false);
      navigate("/brains")
    }catch(error){
      props.setLoading(false);
      console.log("error while saving changes " , error);
      setError("Error while saving changes " + error?.message);
    }
  }
  const [name, setName] = useState(props?.config?.name);
  
  return (<div className="w-full h-full">
    
    <div className = "flex flex-col flex-grow h-full w-full">
    
    <div className = "flex h-[80%] flex-row w-full">
    
    <div className = "flex flex-col w-8/12 ml-[2vw]">
      <NameField value={name} onChange={(e)=>setName(e.target.value)} className = "mt-4"/>
      <div className = "text-3xl ml-4 font-bold mt-5 flex justify-between">

        <div>
          Add your offers here:
        </div>

        <button
        className="flex w-[40%] max-w-[120px] mt-1 items-center bg-black text-white font-bold py-2 px-4 rounded-lg shadow-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black text-sm"
        onClick={(e)=>{addNewOffer()}}
        >
          Add new item
        </button>
      </div>

      <div className="w-[70%] mt-10 flex-grow custom-scrollbar overflow-auto">

        <div>
      {
        offers.map((offer, index) => {
          const confirmDelete = (confirm)=>{
              if(confirm == true){
                setOffers((cur_offers)=>{
                  let nw_offers = [...cur_offers];
                  nw_offers.splice(index, 1);
                  return nw_offers;
                })
              }
          }
          
          return <div className={`flex flex-col items-center w-full items-center relative`} >
          <div className="flex w-[100%] mt-2 px-5 rounded-[20px] py-[10px] justify-between items-center bg-gray-100 cursor-pointer" onClick={(e)=>{
              setSelectedOffer(index);
          }}>
              <p>Offer {index}</p>
              <img src = {remove_logo}   alt = "remove offer" className = "w-6 h-6 cursor-pointer" onClick = {(e)=>{ 
                props.askConfirmation("Are you sure you want to delete this offer?", confirmDelete)
                }} />
            </div>

            {
              selectedOffer == index && <JsonVisualEditor obj={offers[index]} setObj={(upd_lambda)=>{updateOffer(index, upd_lambda)}}/>
            } 
          </div>
    })}
      </div>
      </div>


  

      
      {/* <ScrapWebsiteField className = "mt-6" name = {"Website url to offers"}/>
      <KnowledgeBaseField notIndexed={notIndexed} files={files} setFiles={setFiles} className = "mt-6" name = {"Or load files"}/>  */}
    </div>

    {/* <div className = "flex flex-col w-4/12 items-center pt-[3vh]">
          <JsonCardPreview />
    </div>   */}

    
    
  </div>
  

      <div className = "flex flex-col items-center mb-10 mt-8">
                  <IconButton name = "Save changes" background_color = "bg-green-500" text_className = "text-white" onClick={saveChanges} icon_url = {save_changes_button_logo} />
      </div>
  

  </div>

  
  </div>
  );
}




function CoachVersion(props) {
  const navigate = useNavigate();
  const [error, setError] = useState("")

  const update_config = async()=>{
    try{
      await axiosPrivate.post('/update-config', {
        config_id : props.config.config_id,
        name : name,
        instruction : "",
        schema: {
          type: "coach"
        }
      })
    }catch(e){
      console.log("error while updating config")
      setError("Error while updating config" + e?.message + ". Try again later.")
    }

  }
 
  const saveChanges = async ()=>{
    try{
      props.setLoading(true);
      await update_config();
      success_notification("Successfully updated config");
      props.setLoading(false);
      navigate("/brains")
    }catch(error){
      props.setLoading(false);
      console.log("error while saving changes " , error);
      setError("Error while saving changes " + error?.message);
    }
  }
  const [name, setName] = useState(props?.config?.name);
  
  return (<div className="w-full h-full">
    
    <div className = "flex flex-col flex-grow h-full w-full">
    
    <div className = "flex h-[80%] flex-row w-full">
    
    <div className = "flex flex-col w-8/12 ml-[2vw]">
      <NameField value={name} onChange={(e)=>setName(e.target.value)} className = "mt-4"/>
 
      <div className="w-[70%] mt-10 flex-grow custom-scrollbar overflow-auto">

      </div>


  

      
      {/* <ScrapWebsiteField className = "mt-6" name = {"Website url to offers"}/>
      <KnowledgeBaseField notIndexed={notIndexed} files={files} setFiles={setFiles} className = "mt-6" name = {"Or load files"}/>  */}
    </div>

    {/* <div className = "flex flex-col w-4/12 items-center pt-[3vh]">
          <JsonCardPreview />
    </div>   */}

    
    
  </div>
  

      <div className = "flex flex-col items-center mb-10 mt-8">
                  <IconButton name = "Save changes" background_color = "bg-green-500" text_className = "text-white" onClick={saveChanges} icon_url = {save_changes_button_logo} />
      </div>
  

  </div>

  
  </div>
  );
}





function RightPanel() {
    const [files, setFiles] = useState([]);
    const [notIndexed , setNotIndexed] = useState([])
    const [config, setConfig] = useState({});
    const [loading , setLoading] = useState(true);
    const [loadingCaption , setLoadingCaption] = useState("Loading...");
    const [error, setError] = useState("");

    const [name, setName] = useState("");
    const [instructions, setInstructions] = useState("");
    const [knowledge, setKnowledge] = useState("");
    const [website, setWebsite] = useState("");
    const [templateType, setTemplateType] = useState('offers');

    const navigate = useNavigate();
    const onWebsiteChange = (e)=>{
      setWebsite(e.target.value);
    }
    const onNameChange = (e)=>{
      setName(e.target.value);
    }
    const onInstructionsChange = (e)=>{
      console.log("hey " , e.target.value);
      if(e.target.value != undefined){
        setInstructions(e.target.value);
      }
    }
    const location = useLocation();

    const indexFiles = async()=>{
      console.log("indexing files are " , files)
      if(files.length == 0 && website == ""){
        return;
      }
     const formData = new FormData();

      let added = 0;
      Array.from(files).forEach((file) => {
        if(file.file != null){
          added += 1;
          formData.append('files', file.file);
        }
      });
      if(added == 0 && website == ""){
        return;
      }
      formData.append('url' , website)
      formData.append('config_id',config.config_id);

      
      const notIndexed = (await axiosPrivate.post('/upload-files',formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })).data.notIndexed;
      
      console.log("NOT INDXEDED ARE " , notIndexed)
      setNotIndexed(notIndexed);
      if(notIndexed.length > 0){
        throw Error("Couldn't index a file");
      }
    }
    
    const onclick = async (e)=>{
      e.preventDefault();
      setLoadingCaption("Processing updates...");
      setLoading(true)
      setNotIndexed([])
      
      const promise = Promise.all([indexFiles(), update_config()]);
      
      try{
        const res = await promise;
        setLoading(false);
        success_notification("Successfully updated config");
        navigate("/brains")
      }catch(error){
        setLoading(false);
        if(error == Error("Couldn't index a file")){
          error_notification("Couldn't index a file, make sure it's a valid file.")
        }else{
          error_notification("Error occured, try again later.")
        }
        console.log("")
      }
    };
    const update_config = async()=>{

      try{
        await axiosPrivate.post('/update-config', {
          config_id : config.config_id,
          name : name,
          instruction : instructions,
          website : website
        })
      }catch(e){
        console.log("error while updating config")
        setError("Error while updating config" + e?.message + ". Try again later.")
      }

    }


    useEffect(()=>{
      if(location.pathname){
        const split = location.pathname.split("/");
        const config_id = split[split.length - 1]

        axiosPrivate.get(`/get-config/${config_id}`).then(response => {
          setLoading(false);
          console.log("config " , response.data.config);
          const config = response.data.config;
          


          let knowledge_files: any[] = [];

          const trunc = (str) => str.indexOf('_') != -1 ? str.slice(str.indexOf('_') + 1) : str;
          for(let i = 0; i < config.knowledge_files.length; ++i){
            if(config.knowledge_files[i].type == 'file'){
              knowledge_files.push({
                file: null,
                name : trunc(config.knowledge_files[i].file_name),
                progress : 100
              })
            }else{
              setWebsite(config.knowledge_files[i].url);
            }
          }
          setFiles(knowledge_files);
          setName(config.name);
          setInstructions(config.instruction);
          setKnowledge(config.knowledge);
          setConfig(config);

          if(config.schema && config.schema.type == 'offers') {
            setTemplateType('Offers');
          }else{
            if(config.schema && config.schema.type == 'coach'){
              setTemplateType('Coach')
            }else{
              setTemplateType('Basic')
            }
          }
        }).catch(e => {
          console.log("Error while loading configs: " + e?.message)
        });
    }
    
    } , [location])


    const onTemplateSelect = (template)=>{
      askConfirmation("Are you sure you want to change the template? All unsaved changes will be lost.", (confirm)=>{
        if(confirm == true){
          setTemplateType(template);
        }
      });
    }

    const {askConfirmation, isOpen, confirmMessage, setResult} = useConfirmModal();




    return (<div className="flex flex-col w-[85%] h-[100vh]">
            <div className="w-full flex justify-between mt-10 ">
              <span className="ml-[5vw] text-5xl font-bold">Configuration</span>
            
                <ChangeTemplate className="mr-[5vw] mt-2" onSelect={onTemplateSelect}/>
            </div>
              
       
        <div className="ml-10 border-gray-200 border-b-2 mr-10 mt-4"></div>
        <div className = "ml-[3vw] w-[80%] flex-grow overflow-hidden">  

          
          <Loading loading={loading} caption={loadingCaption} size="big" />
          
          {loading == false && templateType == 'Basic' && <div style={{maxHeight: '100%', overflow: 'auto'}}> 
              
              
              <NameField value={name} onChange={onNameChange} className = "mt-4"/>
              <InstructionsField value={instructions} onChange={onInstructionsChange} className = "mt-4" /> 
              <KnowledgeBaseField notIndexed={notIndexed} files={files} setFiles={setFiles} className = "mt-4"/> 
              <ScrapWebsiteField value={website} onChange={onWebsiteChange} className = "mt-4"/>

              <div className = "flex flex-col items-center">
                  <IconButton name = "Save changes" background_color = "bg-green-500" className = "hover:bg-green-400 transition-color duration-200" text_className = "text-white" onClick={onclick} icon_url = {save_changes_button_logo}/>
              </div>
            

              <div>
                {error}
              </div>
            </div>
          }
          {templateType == 'Offers' && <OffersVersion  setLoading={setLoading} config={config}  askConfirmation={askConfirmation} />}
          {templateType == 'Coach' && <CoachVersion  setLoading={setLoading} config={config}  askConfirmation={askConfirmation} />}
        
        </div>
      
        <ConfirmationModal isOpen={isOpen} message={confirmMessage} setResult={setResult} />

    </div>);
}

function ConfigurationPage(props) {
  return (<div className = "flex ">
          <SideBar active_button="Chatbot Brains" />
          <div className="child-line transform -translate-x-1/2 h-[100vh] w-0.5 bg-gray-200" />
          <RightPanel />  
      </div>);
}


export default ConfigurationPage;