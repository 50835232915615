import io from "socket.io-client";
import { getJwtToken } from "./axios.js"
import React, {useState, useEffect} from "react"

const useOnNotify = () => {
    const [socket , setSocket] = useState();
    const [callbacks, setCallbacks] = useState({});//[event : string, callback : (data : any) => void
    
    
    useEffect(()=>{
        console.log("CALLBACKS ARE", callbacks)
    }, [callbacks]);


    const addNotifyCallback = (event, callback) => {
        setCallbacks(currentCallBacks => {
            console.log(" CURRENT CALLBACKS ARE ", JSON.stringify(currentCallBacks))
            console.log(" DAMN IT")
            currentCallBacks[event] = [...(currentCallBacks[event] || []), callback];
            return currentCallBacks;
        }
        );
    }
    const removeNotifyCallback = (event, callback) => {
        console.log(" REMOVING CALLBACK ", event);
        setCallbacks(currentCallBacks => {
            if(currentCallBacks[event]){
                currentCallBacks[event] = currentCallBacks[event].filter((callback_obj, indx)=>callback_obj!=callback);
            }
            return currentCallBacks
         }
        );
    };

    const possible_events = ['new_message', 'new_chat', 'new_user', 'new_notification', 'new_chat_message', 'new_chat_notification', 'new_chat_user', 'new_chat_message_notification', 'new_chat_message_user', 'new_chat_notification_user', 'new_chat_message_notification_user'];
    useEffect(()=>{

        const new_socket = io(process.env.REACT_APP_BACKEND_URL, {
            reconnection: true,
            reconnectionAttempts: 5, // Number of reconnection attempts
            reconnectionDelay: 1000, // Delay between each reconnection attempt in ms
            reconnectionDelayMax: 4000, // Maximum delay between reconnection attempts in ms
            timeout: 7000, // Connection timeout before reconnection in ms
          });
        
        
        setSocket(new_socket);

        new_socket.on("connect", ()=>{
            new_socket.emit("join_room", getJwtToken());
        });

        for(let event of possible_events){
            new_socket.on(event, (data)=>{
                console.log(" GOT EVENT ", event, " DATA ", data)
                if(callbacks[event]){
                    console.log(" gggggggg top g    ")
                    console.log(callbacks[event]);
                    callbacks[event].forEach(callback => callback(data));
                }
            });
        }
       
   
        
        return ()=>{
            new_socket.disconnect();
        }
    }, []);

    return [addNotifyCallback, removeNotifyCallback]
}


export default useOnNotify;

