
import {axiosPrivate} from './axios.js';

async function chatOpenHandle(chat_id: string, platform: string) {
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND_URL}/chat-open-handle`, {
        chat_id: chat_id,
        platform: platform
    });
}

export default chatOpenHandle;
